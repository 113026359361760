<template>
  <div class="news">
    <div class="banner"></div>
    <section class="main_width news_section">
      <div class="breadcrumb_con">
        <Breadcrumb :secondStage="secondStage" />
      </div>
      <ul class="newList">
        <li v-for="(item, index) in newsList" :key="index">
          <div class="date">{{ item.releaseDate }}</div>
          <div class="title" @click="toDetail(item.newsId)">
            {{ item.title }}
          </div>
        </li>
      </ul>
      <div class="pagination" v-if="serviceTotal > 10">
        <pagination
          :total="serviceTotal"
          v-model:currentPage="serPage.currentPageNum"
          v-model:pageSize="serPage.recordsPerPage"
          @search="getNewsList"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import Pagination from '@/common/pagination.vue'
import Breadcrumb from '@/common/breadcrumb.vue'
import { PageData } from '@/types/index'
import { searchByNewsType } from '@/api/news'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'news',
  components: {
    Pagination,
    Breadcrumb
  },
  setup() {
    const serPage = ref<PageData>({ currentPageNum: 1, recordsPerPage: 10 })
    const serviceTotal = ref(0)
    const newsList = ref([])
    const secondStage = ref({
      title: '新闻资讯',
      path: '/news',
      isJump: false
    })
    const router = useRouter()
    function toDetail (newsId: number) {
      router.push(`/newsDetail?newsId=${newsId}`)
    }
    async function getNewsList () {
      const params = Object.assign({
        paginate: serPage.value
      })
      const res: any = await searchByNewsType(params)
      if (res.status === '1') {
        newsList.value = res.data.data
        serviceTotal.value = res.data.total
      }
    }
    getNewsList()
    return {
      serPage,
      serviceTotal,
      newsList,
      secondStage,
      toDetail,
      getNewsList
    }
  }
})
</script>

<style lang="scss" scoped>
.news {
  padding-top: 103px;
  .banner {
    width: 100%;
    height: 380px;
    background: url(~assets/images/news/banner.png) no-repeat 50% 50%;
    background-size: cover;
  }
  .news_section {
    padding-bottom: 96px;
  }
  .breadcrumb_con {
    padding: 38px 0;
    border-bottom: 1px solid #eaeaea;
  }
  .newList {
    li {
      display: flex;
      align-items: center;
      font-size: 22px;
      color: #333333;
      padding: 66px 0;
      border-bottom: 1px solid #eaeaea;
      .date {
        font-weight: bold;
        margin-right: 105px;
      }
      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          color: #e5ba71;
          cursor: pointer;
        }
      }
    }
  }
  .pagination {
    text-align: center;
    margin-top: 135px;
  }
}
</style>
