
import Pagination from '@/common/pagination.vue'
import Breadcrumb from '@/common/breadcrumb.vue'
import { PageData } from '@/types/index'
import { searchByNewsType } from '@/api/news'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'news',
  components: {
    Pagination,
    Breadcrumb
  },
  setup() {
    const serPage = ref<PageData>({ currentPageNum: 1, recordsPerPage: 10 })
    const serviceTotal = ref(0)
    const newsList = ref([])
    const secondStage = ref({
      title: '新闻资讯',
      path: '/news',
      isJump: false
    })
    const router = useRouter()
    function toDetail (newsId: number) {
      router.push(`/newsDetail?newsId=${newsId}`)
    }
    async function getNewsList () {
      const params = Object.assign({
        paginate: serPage.value
      })
      const res: any = await searchByNewsType(params)
      if (res.status === '1') {
        newsList.value = res.data.data
        serviceTotal.value = res.data.total
      }
    }
    getNewsList()
    return {
      serPage,
      serviceTotal,
      newsList,
      secondStage,
      toDetail,
      getNewsList
    }
  }
})
